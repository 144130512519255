import router from "../router";
import Employee from "../apis/Employee";

export default {
    namespaced: true,

    state: {
        auth: {
            login: false,
            user: []
        }
    },

    getters: {
        isLoggedIn(state) {
            return state.auth.login;
        }
    },

    mutations: {
        LOGIN(state, status) {
            state.auth.login = status;
            state.auth.user = [];
        },

        AUTH_USER(state, user) {
            state.auth.user = user;
        },

        LOGOUT(state) {
            localStorage.removeItem("employee-token");
            state.auth.login = false;
            state.auth.user = [];
        }
    },

    actions: {
        login({ commit }, payload) {
            commit("LOGIN", payload);
        },

        authUser({ commit }, payload) {
            commit("AUTH_USER", payload);
        },

        logOut({ commit }) {
            return Employee.logout()
                .then(response => {
                    commit("LOGOUT");
                    console.log(response.data);
                    router.push({ name: "admin-login" });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
