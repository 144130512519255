import Vue from "vue";
import Vuex from "vuex";
import admin from "./admin";
import employee from "./employee";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        admin: admin,
        employee: employee
    }
});
