import router from "../router";
import Admin from "../apis/Admin";
import Employee from "../apis/Employee";

export default {
    namespaced: true,

    state: {
        auth: {
            login: false,
            user: []
        }
    },

    getters: {
        isLoggedIn(state) {
            return state.auth.login;
        }
    },

    mutations: {
        LOGIN(state, status) {
            state.auth.login = status;
            state.auth.user = [];
        },

        AUTH_USER(state, user) {
            state.auth.user = user;
        },

        LOGOUT(state) {
            state.auth.login = false;
            state.auth.user = [];
        }
    },

    actions: {
        login({ commit }, payload) {
            commit("LOGIN", payload);
        },

        authUser({ commit }, payload) {
            commit("AUTH_USER", payload);
        },

        syncLoginStatus({ commit }) {
            let adminToken = localStorage.getItem("admin-token");
            let employeeToken = localStorage.getItem("employee-token");

            console.log("syncLoginStatus");

            if (adminToken) {
                return Admin.auth()
                    .then(response => {
                        console.log("Admin Data", response.data);
                        commit("LOGIN", true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else if (employeeToken) {
                return Employee.auth()
                    .then(response => {
                        console.log("Employee Data", response.data);
                        commit("employee/LOGIN", true, { root: true });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },

        logOut({ commit }) {
            let adminToken = localStorage.getItem("admin-token");
            let employeeToken = localStorage.getItem("employee-token");

            if (adminToken) {
                console.log("logout admin");
                localStorage.removeItem("admin-token");
                return Admin.logout()
                    .then(response => {
                        commit("LOGOUT");
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        router.push({ name: "admin-login" });
                    });
            } else if (employeeToken) {
                console.log("logout employee");
                localStorage.removeItem("employee-token");
                return Employee.logout()
                    .then(response => {
                        commit("employee/LOGOUT", null, { root: true });
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        router.push({ name: "admin-login" });
                    });
            }
        }
    }
};
