require("./bootstrap");

import Vue from "vue";
import router from "./router";
import store from "./store/index";

import vuetify from "./plugins/vuetify";

//Main pages
import App from "./views/app.vue";

const app = new Vue({
    el: "#app",
    router,
    store,
    vuetify,
    components: { App }
});
