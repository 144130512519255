import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: {
            light: {
                primary: colors.amber.darken2, // #E53935
                secondary: colors.amber.lighten1, // #FFCDD2
                accent: colors.indigo.base // #3F51B5
            }
        }
    }
};

export default new Vuetify(opts);
