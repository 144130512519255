import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store/index";

Vue.use(VueRouter);

const routes = [
    {
        name: "admin-login",
        path: "/admin/login",
        component: require("./views/pages/admin/Login.vue").default,
        beforeEnter: onlyForGuest
    },
    {
        name: "admin-dashboard",
        path: "/admin",
        component: require("./views/pages/admin/Documents.vue").default,
        beforeEnter: commonForAll
    },
    {
        name: "admin-employees",
        path: "/admin/employees",
        component: require("./views/pages/admin/Employees.vue").default,
        beforeEnter: onlyForAdmin
    },
    {
        name: "admin-documents",
        path: "/admin/documents",
        component: require("./views/pages/admin/Documents.vue").default,
        beforeEnter: commonForAll
    },
    {
        name: "admin-change-password",
        path: "/admin/change-password",
        component: require("./views/pages/admin/ChangePassword.vue").default,
        beforeEnter: onlyForAdmin
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});

// Middlewares
router.beforeEach(async (to, from, next) => {
    console.log("beforeEach");
    await store.dispatch("admin/syncLoginStatus");
    console.log("from", from);
    console.log("to", to);
    next();
});

function onlyForGuest(to, from, next) {
    console.log("onlyForGuest");
    if (
        store.getters["admin/isLoggedIn"] ||
        store.getters["employee/isLoggedIn"]
    ) {
        next({ name: "admin-documents" });
    }
    next();
}

function onlyForAdmin(to, from, next) {
    console.log("onlyForAdmin");
    if (!store.getters["admin/isLoggedIn"]) {
        if (store.getters["employee/isLoggedIn"])
            next({ name: "admin-documents" });
        else next({ name: "admin-login" });
    }
    next();
}

function commonForAll(to, from, next) {
    console.log("commonForAll");
    if (
        !store.getters["admin/isLoggedIn"] &&
        !store.getters["employee/isLoggedIn"]
    ) {
        console.log("not logged in");
        next({ name: "admin-login" });
    }
    next();
}

export default router;
