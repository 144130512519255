<template>
    <div>
        <h1 class="text-h4 mb-4">Change Password</h1>

        <v-container>
            <v-row no-gutters>
                <v-col md="6" lg="4">
                    <form action="" method="POST" @submit.prevent="save">
                        <v-text-field
                            type="password"
                            label="Old Password"
                            v-model="form_items.old_password"
                            :error-messages="
                                errors && errors.old_password
                                    ? errors.old_password[0]
                                    : ''
                            "
                        ></v-text-field>

                        <v-text-field
                            type="password"
                            label="New Password"
                            v-model="form_items.new_password"
                            :error-messages="
                                errors && errors.new_password
                                    ? errors.new_password[0]
                                    : ''
                            "
                        ></v-text-field>

                        <v-text-field
                            type="password"
                            label="Confirm New Password"
                            v-model="form_items.confirm_new_password"
                            :error-messages="
                                errors && errors.confirm_new_password
                                    ? errors.confirm_new_password[0]
                                    : ''
                            "
                        ></v-text-field>

                        <v-btn
                            color="primary mt-4 mb-5"
                            large
                            type="submit"
                            @click.prevent="save"
                            :loading="btnLoading"
                        >
                            Submit
                        </v-btn>

                        <v-alert
                            color="red"
                            dense
                            text
                            type="error"
                            v-if="globalError"
                            v-html="globalError"
                        ></v-alert>

                        <v-alert
                            color="green"
                            dense
                            text
                            type="success"
                            v-if="successMessage"
                            v-html="successMessage"
                        ></v-alert>
                    </form>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Admin from "../../../apis/Admin";

export default {
    data() {
        return {
            btnLoading: false,
            form_items: {
                old_password: "",
                new_password: "",
                confirm_new_password: ""
            },
            errors: null,
            globalError: null,
            successMessage: null
        };
    },

    methods: {
        save() {
            this.successMessage = null;
            this.btnLoading = true;
            Admin.changePassword({
                ...this.form_items
            })
                .then(response => {
                    this.successMessage = response.data.message;

                    this.form_items.old_password = "";
                    this.form_items.new_password = "";
                    this.form_items.confirm_new_password = "";
                    this.errors = null;
                    this.globalError = null;
                })
                .catch(error => {
                    console.log(error);

                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors;
                    } else {
                        this.globalError = error.response.data.message;
                    }
                })
                .finally(() => {
                    this.btnLoading = false;
                });
        }
    }
};
</script>
