<template>
    <v-app>
        <v-navigation-drawer
            v-model="drawer"
            app
            clipped
            v-if="$route.name != 'admin-login'"
        >
            <v-list>
                <v-subheader>SECTIONS</v-subheader>
                <v-list-item-group color="primary">
                    <v-list-item
                        v-for="(item, i) in navItems"
                        :key="i"
                        :to="{ name: item.link }"
                        exact
                    >
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="item.text"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app clipped-left v-if="$route.name != 'admin-login'">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <!-- <v-toolbar-title class="pl-2">Staff Portal</v-toolbar-title> -->
            <img
                src="/morningfoodslogo.png"
                height="100%"
                class="pt-1 pb-1"
                alt=""
            />

            <v-btn
                color="error"
                large
                absolute
                right
                outlined
                dark
                :loading="loading"
                @click.prevent="logOut()"
                >Log out</v-btn
            >
        </v-app-bar>

        <v-main>
            <div class="px-5 py-5">
                <router-view></router-view>
            </div>
        </v-main>
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            drawer: null,
            loading: false,
            items: [
                /*  {
                    link: "admin-documents",
                    text: "Dashboard",
                    icon: "mdi-view-dashboard"
                }, */
                {
                    link: "admin-employees",
                    text: "Employees",
                    icon: "mdi-briefcase-account"
                },
                {
                    link: "admin-documents",
                    text: "Documents",
                    icon: "mdi-file-document-multiple"
                },
                {
                    link: "admin-change-password",
                    text: "Change Password",
                    icon: "mdi-key"
                }
            ]
        };
    },

    methods: {
        logOut() {
            this.$store.dispatch("admin/logOut").then(() => {
                this.loading = false;
            });
        }
    },

    computed: {
        navItems() {
            return this.items.filter(item => {
                return (
                    (this.$store.getters["employee/isLoggedIn"] &&
                        item.link != "admin-employees" &&
                        item.link != "admin-change-password") ||
                    this.$store.getters["admin/isLoggedIn"]
                );
            });
        }
    }
};
</script>
