<template>
    <v-dialog width="500" v-model="dialog" persistent>
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>Staff Portal - Log in</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="pt-5">
                <v-form @submit.prevent="logIn">
                    <v-text-field
                        label="Email"
                        v-model="email"
                        v-if="login_as == 'admin'"
                        :error-messages="
                            errors && errors.email ? errors.email[0] : ''
                        "
                    ></v-text-field>
                    <v-text-field
                        label="Surname"
                        v-model="surname"
                        v-if="login_as == 'employee'"
                        :error-messages="
                            errors && errors.surname ? errors.surname[0] : ''
                        "
                    ></v-text-field>

                    <v-text-field
                        :label="passwordFieldLabel"
                        type="password"
                        v-model="password"
                        :error-messages="
                            errors && errors.password ? errors.password[0] : ''
                        "
                    ></v-text-field>

                    <v-radio-group row v-model="login_as">
                        <v-radio label="Employee" value="employee"></v-radio>
                        <v-radio label="Admin" value="admin"></v-radio>
                    </v-radio-group>

                    <v-alert
                        color="red"
                        dense
                        text
                        type="error"
                        v-if="globalError"
                        v-html="globalError"
                    ></v-alert>

                    <div class="text-right">
                        <v-btn
                            type="submit"
                            large
                            color="primary"
                            @click.prevent="logIn"
                            :loading="loading"
                            >Log in</v-btn
                        >
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import Admin from "../../../apis/Admin";
import Employee from "../../../apis/Employee";

export default {
    data() {
        return {
            dialog: true,
            loading: false,
            email: "",
            surname: "",
            password: "",
            login_as: "employee",
            errors: null,
            globalError: null
        };
    },

    computed: {
        passwordFieldLabel() {
            if (this.login_as == "employee") {
                return "Clock Number";
            } else {
                return "Password";
            }
        }
    },

    methods: {
        logIn() {
            this.loading = true;
            if (this.login_as == "employee") {
                Employee.login({
                    surname: this.surname,
                    password: this.password
                })
                    .then(response => {
                        console.log(response.data);

                        if (response.data.token) {
                            this.$store.dispatch("employee/login", true);
                            this.$store.dispatch(
                                "employee/authUser",
                                response.data.user
                            );
                            localStorage.setItem(
                                "employee-token",
                                response.data.token
                            );
                            this.$router.push({ name: "admin-documents" });
                        }
                    })
                    .catch(error => {
                        console.log(error);

                        if (error.response.status == 422) {
                            this.errors = error.response.data.errors;
                        } else {
                            this.globalError = error.response.data.message;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                Admin.login({
                    email: this.email,
                    password: this.password
                })
                    .then(response => {
                        console.log(response.data);

                        if (response.data.token) {
                            this.$store.dispatch("admin/login", true);
                            this.$store.dispatch(
                                "admin/authUser",
                                response.data.user
                            );
                            localStorage.setItem(
                                "admin-token",
                                response.data.token
                            );
                            this.$router.push({ name: "admin-documents" });
                        }
                    })
                    .catch(error => {
                        console.log("error", error);
                        if (error.response.status == 422) {
                            this.errors = error.response.data.errors;
                        } else {
                            this.globalError = error.response.data.message;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },

        clearErrors() {
            this.errors = null;
            this.globalError = null;
            console.log("Clear errors");
        }
    },

    watch: {
        login_as: function(oldVal, newVal) {
            if (oldVal != newVal) {
                this.clearErrors();
            }
        }
    }
};
</script>
