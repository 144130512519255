<template>
    <div>
        <h1 class="text-h4 mb-4">Employees</h1>

        <v-data-table
            :headers="headers"
            :items="employees"
            :search="search"
            :options.sync="options"
            :server-items-length="totalRecords"
            :loading="loading"
            sort-by="surname"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>

                    <v-spacer></v-spacer>

                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                New Employee
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <form
                                action=""
                                method="POST"
                                @submit.prevent="save"
                            >
                                <v-card-text>
                                    <v-container>
                                        <v-text-field
                                            v-model="editedItem.surname"
                                            label="Surname"
                                            :error-messages="
                                                errors && errors.surname
                                                    ? errors.surname[0]
                                                    : ''
                                            "
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="editedItem.clocknumber"
                                            label="Clock Number"
                                            :error-messages="
                                                errors && errors.clocknumber
                                                    ? errors.clocknumber[0]
                                                    : ''
                                            "
                                        ></v-text-field>

                                        <v-alert
                                            color="red"
                                            dense
                                            text
                                            type="error"
                                            v-if="globalError"
                                            v-html="globalError"
                                        ></v-alert>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click.prevent="save"
                                        type="submit"
                                        :loading="btnLoading"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </form>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="headline"
                                >Are you sure you want to delete this
                                item?</v-card-title
                            >
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDelete"
                                    >Cancel</v-btn
                                >
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirm"
                                    :loading="btnLoading"
                                    >OK</v-btn
                                >
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
            <!--  <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                    Reset
                </v-btn>
            </template> -->
        </v-data-table>
    </div>
</template>

<script>
import Admin from "../../../apis/Admin";

export default {
    data() {
        return {
            dialog: false,
            dialogDelete: false,
            search: "",
            headers: [
                { text: "Surname", value: "surname" },
                { text: "Clock Number", value: "clocknumber" },
                { text: "Actions", value: "actions", sortable: false }
            ],
            editedIndex: -1,
            editId: 0,
            editedItem: {
                name: "",
                surname: "",
                clocknumber: ""
            },
            defaultItem: {
                name: "",
                surname: "",
                clocknumber: ""
            },
            totalRecords: 0,
            employees: [],
            loading: true,
            btnLoading: false,
            options: {},

            errors: null,
            globalError: null
        };
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Employee" : "Edit Employee";
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        options: {
            handler() {
                this.getDataFromApi();
            },
            deep: true
        },
        search(val) {
            this.getDataFromApi();
        }
    },

    mounted() {
        this.getDataFromApi();
    },
    methods: {
        initialize() {
            this.getDataFromApi();
        },
        editItem(item) {
            this.editId = item.id;
            this.editedIndex = this.employees.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            console.log(item);
            this.editId = item.id;
            this.editedIndex = this.employees.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            // Delete
            this.btnLoading = true;
            Admin.deleteEmployee(this.editId)
                .then(response => {
                    console.log(response);

                    this.employees.splice(this.editedIndex, 1);
                    this.editId = 0;

                    this.closeDelete();
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.btnLoading = false;
                });
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.resetErrors();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        save() {
            if (this.editedIndex > -1) {
                // Update
                this.btnLoading = true;
                Admin.updateEmployee(
                    {
                        surname: this.editedItem.surname,
                        clocknumber: this.editedItem.clocknumber
                    },
                    this.editId
                )
                    .then(response => {
                        console.log(response);

                        Object.assign(
                            this.employees[this.editedIndex],
                            this.editedItem
                        );
                        this.editId = 0;
                        this.close();
                    })
                    .catch(error => {
                        console.log(error);

                        if (error.response.status == 422) {
                            this.errors = error.response.data.errors;
                        } else {
                            this.globalError = error.response.data.message;
                        }
                    })
                    .finally(() => {
                        this.btnLoading = false;
                    });
            } else {
                // Create
                this.btnLoading = true;
                Admin.createEmployee({
                    surname: this.editedItem.surname,
                    clocknumber: this.editedItem.clocknumber
                })
                    .then(response => {
                        this.$set(this.editedItem, "id", response.data.data.id);

                        this.employees.push(this.editedItem);
                        this.close();
                    })
                    .catch(error => {
                        console.log(error);

                        if (error.response.status == 422) {
                            this.errors = error.response.data.errors;
                        } else {
                            this.globalError = error.response.data.message;
                        }
                    })
                    .finally(() => {
                        this.btnLoading = false;
                    });
            }
        },

        getDataFromApi() {
            this.loading = true;
            this.doApiCall().then(data => {
                this.employees = data.items;
                this.totalRecords = data.total;
                this.loading = false;
            });
        },

        doApiCall() {
            return new Promise((resolve, reject) => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options;

                Admin.api()
                    .get(
                        `/employee/all?page=${page}&per_page=${itemsPerPage}&sort_by=${sortBy}&sort_desc=${sortDesc}&keyword=${this.search}`
                    )
                    .then(response => {
                        //console.log(response.data);

                        let items = response.data.data.data;
                        const total = response.data.data.total;

                        let search = this.search;
                        if (search) {
                            items = items.filter(function(item) {
                                return Object.values(item)
                                    .join(",")
                                    .toLowerCase()
                                    .includes(search);
                            });
                        }

                        if (itemsPerPage > 0) {
                            items.slice(
                                (page - 1) * itemsPerPage,
                                page * itemsPerPage
                            );
                        }

                        resolve({
                            items,
                            total
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {});
            });
        },

        resetErrors() {
            this.errors = null;
            this.globalError = null;
        }
    }
};
</script>
