import axios from "axios";

let BaseApi = axios.create({
    baseURL:
        process.env.MIX_APP_MODE == "local"
            ? process.env.MIX_API_URL_DEV
            : process.env.MIX_API_URL
});

let Api = function() {
    let token = localStorage.getItem("employee-token");
    if (token) {
        BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    return BaseApi;
};

export default {
    api() {
        return Api;
    },

    login(form) {
        return Api().post("/employee/login", form);
    },

    logout() {
        return Api().post("/employee/logout");
    },

    auth() {
        return Api().post("/employee/me");
    }
};
